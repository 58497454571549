import { useState } from 'react';
import { Button, Tooltip } from 'sw-ui';

import CompanyService from '../../../../../../bi/services/company/service';

import LinkToNewTab from '../../../../../../components/LinkToNewTab';
import { SendReceiptToEmailDialog } from '../SendReceiptToEmailDialog.tsx';

import { formatDate } from '../../../../../../utils/date';
import MoneyFormat from '../../../../../../bi/utils/money';

import { LABELS, TransactionsStatusMap } from '../../constants.ts';
import COMPONENTS from '../../../../../../bi/constants/components';

import { ITransaction } from '../../types.ts';

import styles from '../../styles/table.module.scss';

const {
  BUTTON: { THEME: { FLAT } },
  TOOLTIP: { POSITION: { TOP } },
} = COMPONENTS;

interface ITableRowProps {
  transaction: ITransaction;
  companyId: number;
  accountId: number;
  companyService: CompanyService;
  getTransactionAuthorEmailById: (userId: string) => string;
}

export const TableRow = ({
  transaction,
  companyId,
  accountId,
  companyService,
  getTransactionAuthorEmailById,
}: ITableRowProps) => {
  const {
    amount,
    fee,
    description,
    eventDate,
    tripId,
    receiptId,
  } = transaction;

  const [showSendReceiptDialog, setShowSendReceiptDialog] = useState(false);

  const openSendReceiptDialog = () => {
    setShowSendReceiptDialog(true);
  };

  const closeSendReceiptDialog = () => {
    setShowSendReceiptDialog(false);
  };

  const getEntrance = () => {
    if (amount && amount > 0) return MoneyFormat.moneyWithDecimal(amount, true);

    return null;
  };

  const getWriteDowns = () => {
    if (amount && amount < 0) return MoneyFormat.moneyWithDecimal(amount, true);

    return null;
  };

  const getFee = () => {
    if (fee && fee > 0) return MoneyFormat.moneyWithDecimal(fee, true);

    return null;
  };

  const getStatus = () => {
    return TransactionsStatusMap[transaction.status] || '';
  };

  const renderSendReceiptToEmail = () => {
    const renderTooltip = () => {
      if (receiptId) return null;

      return (
        <Tooltip position={ TOP }>
          <div className={ styles.tooltip }>
            { LABELS.EMPTY_RECEIPT }
          </div>
        </Tooltip>
      );
    };

    return (
      <div className='sw-tooltip-wrapper'>
        <Button
          theme={ FLAT }
          className={ styles.sendReceiptButton }
          disabled={ !receiptId }
          onClick={ openSendReceiptDialog }
        >
          Отправить чек на почту
        </Button>
        { renderTooltip() }

        <SendReceiptToEmailDialog
          show={ showSendReceiptDialog }
          onClose={ closeSendReceiptDialog }
          receiptId={ receiptId }
          userEmail={ getTransactionAuthorEmailById(transaction.userId) }
          companyService={ companyService }
        />
      </div>
    );
  };

  return (
    <tr className={ styles.tableRow }>
      <td className={ styles.tableCell }>{ formatDate(eventDate) }</td>
      <td className={ styles.tableCell }>{ description }</td>
      <td className={ styles.tableCell }>
        { tripId && (
          <LinkToNewTab to={ `/account/${accountId}/company/${companyId}/trip/${tripId}` }>
            <span className={ styles.link }>
              { tripId }
            </span>
          </LinkToNewTab>
        ) }
      </td>
      <td className={ styles.tableCell }>{ getEntrance() }</td>
      <td className={ styles.tableCell }>{ getWriteDowns() }</td>
      <td className={ styles.tableCell }>{ getFee() }</td>
      <td className={ styles.tableCell }>{ getStatus() }</td>
      <td className={ styles.tableCell }>{ renderSendReceiptToEmail() }</td>
    </tr>
  );
};
