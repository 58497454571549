import { Dialog, Loading } from 'sw-ui';
import {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';

import CompanyService from '../../../../../bi/services/company/service';

import Input from '../../../../../components/input';
import { Button, FlatButton } from '../../../../../components/button';

import { isEmail } from '../../../../../bi/utils/emailValidator';

import { LABELS } from '../constants.ts';

import styles from '../styles/sendReceiptDialog.module.scss';

const enum Stages {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface ISendReceiptToEmailDialogProps {
  show: boolean;
  onClose: () => void;
  receiptId: string | null;
  userEmail: string;
  companyService: CompanyService;
}

export const SendReceiptToEmailDialog = ({
  show,
  onClose,
  receiptId,
  userEmail,
  companyService,
}: ISendReceiptToEmailDialogProps) => {
  const [email, setEmail] = useState(userEmail);
  const [currentStage, setCurrentStage] = useState<Stages>(Stages.DEFAULT);

  const { paidByCard: { isLoadingSendingReceipt } } = companyService.get();

  useEffect(() => {
    setCurrentStage(Stages.DEFAULT);
  }, [show]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const getValidationError = () => {
    if (isEmail(email)) return '';

    return LABELS.SEND_RECEIPT_DIALOG.VALID_ERROR;
  };

  const handleSend = async () => {
    try {
      await companyService.sendReceiptToEmail({ receiptId, email });
      setCurrentStage(Stages.SUCCESS);
    } catch (e) {
      setCurrentStage(Stages.ERROR);
    }
  };

  const renderDefaultBody = () => (
    <>
      <h3>{ LABELS.SEND_RECEIPT_DIALOG.TITLE }</h3>

      <div className={ styles.inputContainer }>
        <Input
          field='email'
          type='email'
          value={ email }
          onChange={ onChange }
          valid={ getValidationError() }
        />
      </div>

      <div className={ styles.actions }>
        <FlatButton onClick={ onClose }>
          { LABELS.SEND_RECEIPT_DIALOG.CANCEL_BUTTON }
        </FlatButton>
        <Button
          isLoading={ isLoadingSendingReceipt }
          label={ LABELS.SEND_RECEIPT_DIALOG.SEND_BUTTON }
          disabled={ !isEmail(email) }
          onClick={ handleSend }
        >
          Отправить
        </Button>
      </div>
    </>
  );

  const renderLoading = () => {
    return (
      <div className={ styles.loading }>
        <Loading />
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <>
        <p className={ styles.bodyText }>
          { LABELS.SEND_RECEIPT_DIALOG.SEND_SUCCESS }
        </p>

        <div className={ styles.actions }>
          <FlatButton onClick={ onClose }>
            { LABELS.SEND_RECEIPT_DIALOG.CLOSE_BUTTON }
          </FlatButton>
        </div>
      </>
    );
  };

  const renderError = () => {
    return (
      <>
        <p className={ styles.bodyText }>
          { LABELS.SEND_RECEIPT_DIALOG.SEND_ERROR }
        </p>

        <div className={ styles.actions }>
          <FlatButton onClick={ onClose }>
            { LABELS.SEND_RECEIPT_DIALOG.CLOSE_BUTTON }
          </FlatButton>
        </div>
      </>
    );
  };

  const renderBody = () => {
    if (isLoadingSendingReceipt) return renderLoading();

    switch (currentStage) {
      case Stages.DEFAULT:
        return renderDefaultBody();

      case Stages.SUCCESS:
        return renderSuccess();

      case Stages.ERROR:
        return renderError();

      default:
        return null;
    }
  };

  if (!show || !receiptId) return null;

  return (
    <Dialog onClick={ onClose }>
      <div className={ styles.dialog }>
        { renderBody() }
      </div>
    </Dialog>
  );
};
