export const FROM_LAST_MONTH = 'sinceLastMonth';
export const ALL_TIME = 'allTime';
export const LIMIT_ON_PAGE = 10;

export const LABELS = {
  TRANSACTION_AUTHOR: 'Плательщик',
  TRANSACTION_AUTHOR_SURNAME: 'Фамилия плательщика',
  SHOW_OPERATIONS: 'Отображать операции',
  FROM_PREVIOUS_MONTH: 'c предыдущего месяца',
  ALL_TIME: 'за все время',
  EMPTY_TRANSACTIONS: 'Транзакции отсутствуют',
  EMPTY_RECEIPT: 'Чек отстутсвует',
  SEND_RECEIPT_DIALOG: {
    TITLE: 'Отправить чек на e-mail',
    VALID_ERROR: 'Введите корректный email',
    SEND_ERROR: 'Чек не был отправлен. Введите другой E-mail',
    SEND_SUCCESS: 'Чек успешно отправлен',
    SEND_BUTTON: 'Отправить',
    CANCEL_BUTTON: 'Отмена',
    CLOSE_BUTTON: 'Закрыть',
  },
};

export const TransactionsStatusMap = {
  WaitingPayment: 'Ожидает оплаты',
  Complete: 'Завершена',
  Authorization: 'На авторизации',
  Suspended: 'Отменена',
};
