import { Moment } from 'moment';

import RangeDatePicker from '../../../../../../components/RangeDatePicker';

import {
  getCurrentDate,
  getSmartwayBirthDate,
  getStartDateOfLastMonth,
} from '../../../../../../utils/date';

import { ACCOUNTTEXT } from '../../../../../../bi/constants/account';
import {
  ALL_TIME,
  FROM_LAST_MONTH,
  LABELS,
} from '../../constants.ts';

import styles from '../../styles/styles.module.scss';

interface IFiltersProps {
  dates: {
    startDate: Moment,
    endDate: Moment,
  };
  maxDate: Moment;
  onSetDates: ({ startDate, endDate }: { startDate: Moment, endDate: Moment }) => void;
}

export const DateFilter = ({
  dates,
  maxDate,
  onSetDates,
}: IFiltersProps) => {
  const handleSetDate = (event, position) => {
    event.preventDefault();

    if (position === FROM_LAST_MONTH) {
      onSetDates({
        startDate: getStartDateOfLastMonth(),
        endDate: getCurrentDate(),
      });
    }

    if (position === ALL_TIME) {
      onSetDates({
        startDate: getSmartwayBirthDate(),
        endDate: getCurrentDate(),
      });
    }
  };

  const handleChangeDate = (field, value) => {
    if (!dates[field].isSame(value)) {
      onSetDates({ ...dates, [field]: value });
    }
  };

  return (
    <div className={ styles.dateRange }>
      <span className={ styles['date-text'] }>
        { LABELS.SHOW_OPERATIONS }
      </span>

      <RangeDatePicker
        startDate={ dates.startDate }
        endDate={ dates.endDate }
        maxDateLimit={ maxDate }
        onChange={ handleChangeDate }
        placeholderNameFrom={ ACCOUNTTEXT.FROM }
        placeholderNameTo={ ACCOUNTTEXT.TO }
      />

      <div>
        <span>(&nbsp;</span>
        <a
          className={ styles.link }
          onClick={ (event) => handleSetDate(event, FROM_LAST_MONTH) }
        >
          { LABELS.FROM_PREVIOUS_MONTH }
        </a>
        <span>,&emsp;</span>
        <a
          className={ styles.link }
          onClick={ (event) => handleSetDate(event, ALL_TIME) }
        >
          { LABELS.ALL_TIME }
        </a>
        <span>&nbsp;)</span>
      </div>

    </div>
  );
};
