import { Paginate } from 'sw-ui';

import CompanyService from '../../../../../../bi/services/company/service';

import { TableRow } from './TableRow.tsx';

import MoneyFormat from '../../../../../../bi/utils/money';

import { ITransaction } from '../../types.ts';

import styles from '../../styles/table.module.scss';

interface ITableProps {
  transactions: ITransaction[];
  companyId: number;
  accountId: number;
  currentPage: number;
  itemsOnPage: number;
  totalItems: number;
  companyService: CompanyService;
  onChangePage: (event: unknown, newPage: number) => void;
  getTransactionAuthorEmailById: (userId: string) => string;
}

const HEAD_LABELS = [
  { label: 'Дата', withPriceMark: false },
  { label: 'Операция', withPriceMark: false },
  { label: 'Номер заказа', withPriceMark: false },
  { label: 'Поступление', withPriceMark: true },
  { label: 'Списание', withPriceMark: true },
  { label: 'Комиссия', withPriceMark: true },
  { label: 'Статус транзакции', withPriceMark: false },
  { label: 'Чек', withPriceMark: false },
];

export const Table = ({
  transactions,
  companyId,
  accountId,
  currentPage,
  itemsOnPage,
  totalItems,
  companyService,
  onChangePage,
  getTransactionAuthorEmailById,
}: ITableProps) => {
  const renderTableHeader = () => (
    <thead>
      <tr>
        { HEAD_LABELS.map(({ label, withPriceMark }) => (
          <th className={ styles.tableHeadCell } key={ label }>
            { withPriceMark ? MoneyFormat.delimitedTextWithPrice(label) : label }
          </th>
        )) }
      </tr>
    </thead>
  );

  const renderTableBody = () => (
    <tbody>
      { transactions?.map((transaction) => (
        <TableRow
          transaction={ transaction }
          key={ transaction.id }
          accountId={ accountId }
          companyId={ companyId }
          companyService={ companyService }
          getTransactionAuthorEmailById={ getTransactionAuthorEmailById }
        />
      )) }
    </tbody>
  );

  return (
    <>
      <table className={ styles.table }>
        { renderTableHeader() }
        { renderTableBody() }
      </table>

      <Paginate
        handlePrevious={ onChangePage }
        handlePage={ onChangePage }
        handleNext={ onChangePage }
        currentPage={ currentPage }
        itemsOnPage={ itemsOnPage }
        totalItems={ totalItems }
      />
    </>
  );
};
